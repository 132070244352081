.tokenx-form {
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-bottom: 11px;
}

.tokenx-form__input {
  height: 43px;

  &:not(&--native) {
    background-color: rgba(#5C5B52, 0.1);
  }

  &--native {
    border-radius: 6px;
    border: 1.3px solid #5C5B52;
    background: #FFF;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: #FFF;
    padding-right: 10px;
    padding-left: 10px;

    &:focus {
      border: 2px solid #4f758b;
      outline: 0;
    }
  }

  &--error {
    color: #c9001f;
    border-bottom: 3px solid #c9001f
  }

  iframe {
    background-color: #FFF;
    border: 0;
    width: 100%;
    height: 100%;
  }
}

.tokenx-form__error {
  display: flex;
  gap: 5px;
  align-items: center;
  font-family: "Roboto_Regular",Verdana,sans-serif;
  line-height: .9375rem;
  font-size: .75rem;
  color: #c9001f;
  margin-top: 4px;

  svg {
    margin-top: -3px;
  }
}
