


@media (max-width: 990px) {

  .cc-svg {
    width: 20px;
    height: 20px;
  }

  .cc-column:first-child  {
    /* width: 30px; */
    margin: 2px;
  }
  
  .cc-column:nth-child(2) {
    max-width: 170px;
  }
  
  .cc-column:nth-child(3) {
    max-width: 55px;
  }
  

}


@media (max-width: 400px) {

  /* .cc-column:first-child  {
    display: none;
  }
   */
  /* BRANDSITE NEEDS  */
  .cc-iframe-container {
    width: 303px;
  }
}
@media (min-width: 990px) {


  .cc-svg {
    width: 30px;
    height: 30px;
  }

  
  .cc-column:first-child  {
    /* width: 10%; */
    margin: 10px;
  }
  
  .cc-column:nth-child(2) {
    max-width: 200px;
  }

  .cc-column:nth-child(3) {
    max-width: 70px;
    margin-left: 30px;
  }
}


.cc-iframe-container {
  box-shadow: none;
  height: 60px;
  width: 100%;
  border: 1px solid #776e64;
  border-radius: 6px;
}


.cc-iframe-container-valid {
  border-bottom: 3px solid #4f758b;
}

.cc-iframe-container-focus {
  /* border-bottom: 3px solid #4f758b; */
  border: 3px solid #4f758b;
}

/* .cc-iframe-container-empty {

  border: unset;
} */


.cc-row {
  display: flex;
}

.cc-column {
  justify-content: center;
  display: flex;
}

.cc-column:not(:last-child) {
  border-right: none;
}

.cc-column:first-child  {
  flex: none;
  min-width: fit-content;
  place-self: center;
  /* display: none;  */  

}

.cc-column:nth-child(2) {
  flex: none;
}

.cc-column:nth-child(3) {
  flex: none;
  margin-right: 10px;
  min-width: fit-content;
  place-self: center;
}

/* .cc-column:last-child  {
  flex: none;
}
 */


.tokenExInputError {

    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #CB4D1B;
    text-align: start;
}
