.menu-item {
    padding: 0 2px;
    margin: 5px 10px;
    user-select: none;
    cursor: pointer;
    border: none;
    outline: none;

    background-color: unset;
    border: unset;
    font-family: Veneer_Clean, Verdana, serif;
    font-size: 1.1rem;
    color:white
  }
  .menu-item:hover {
    color:white
  }
  .menu-item.active {
    outline: none;
    text-decoration: underline 2px;
    text-underline-offset: 5px;
    font-size: 1.4em;
    border: unset;
    color: #F2A900
  }
  .scroll-menu-arrow {
    padding: 10px;
    cursor: pointer;
  }
  .horizontal-menu{

    justify-content:center
  }
.horizontal-menuscroll{
  background-color: #643122;
  color: #fff;
}
