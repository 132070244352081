.cb-tokenex-input {
    outline: none;
    color: #653024;
    background-color: transparent;
    font-weight: 400;
    max-width: 55px;
    background-color: transparent;
    border:none;
    line-height: 1.5;
    height: 53px;
    margin-bottom: 2px;
 }

/* iPhone (portrait and landscape) */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 2) {
    .cb-tokenex-input {
        padding: 0;
    }
}

/* iPad (portrait and landscape) */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 2) {
    .cb-tokenex-input {
        padding: 0;
    }
}

.cb-tokenex-input.invalid {
    color:#94273a
}

.cb-tokenex-input:focus {
    margin-bottom: 0px;
}



.cb-input-container {
    position: relative;
    color: #653024;
    max-width: 55px;
}


.expiry-mb1 {
    margin-bottom: 1px;
}
.floating-label {
    position: absolute;
    top: 22px;
    left: 0;
    pointer-events: none;
    transition: top 0.2s ease, opacity 0.2s ease;
    opacity: 0;
    color: red;
    display: none;
}

.cb-input-container.invalid .floating-label {
    top: -40px;
    opacity: 1;
}

.cb-input-container:not(.invalid) .floating-label {
    opacity: 0;
}


  @media (max-width: 400px) {

    .cb-tokenex-input {
        font-size: 1.1rem;
     }
    
  }


  @media (min-width: 401px) {

    .cb-tokenex-input {
        font-size: 1.1rem;
     }
    
  }
