.vehiclemenu-item {
  padding: 0 2px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: unset;
  border: unset;
  font-family: Veneer_Clean, Verdana, serif;
  font-size: 1.1rem;
  color:#653024;
  text-align: center;

}
/* .menu-item:hover {
  color:black
} */

.menu-icon{
  text-align: center;
}
.vehiclemenu-item.active {
  color:black;
  text-decoration: underline;
  font-size: x-large;

}

.menu-item-wrapper {
  border: 1px transparent solid;
}

.scroll-menu-arrow {
  padding: 10px;
  cursor: pointer;
}
.horizontal-vehiclemenu{
  justify-content:center
}
